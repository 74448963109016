import React, { FC } from 'react';
import { Button as ButtonUI, Form, Input, Typography, message } from 'antd';
import styled from 'styled-components'

import { loginUser } from 'src/store/auth';

const { Title } = Typography;

const Wrap = styled.div`
  position: fixed;
  top:0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000008a;
  z-index: 999;
  backdrop-filter: blur(5px);
`;

const Container = styled.div`
  width: 340px;
  min-height: 200px;
  background: white;
  padding: 0px 40px 32px 40px;
  border-radius: 4px;
`;

const Button = styled(ButtonUI)`
  margin-top: 16px;
  width: 100%;
`;


export const AuthForm: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();


  const onFinish = (values: { login: string; password: string; }) => {
    loginUser(values)
      .then((data) => {
        sessionStorage.token = data.token;
        window.location.href = '/';
      })
      .catch(() => {
        message.error('Ошибка авторищации')
      })
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Wrap>
      <Container>
        <Title level={3}>Авторизация</Title>
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Логин"
            name="login"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form>
      </Container>
    </Wrap>
  );
}
