import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { isDevelopment } from './is_development';

type RequestInterceptor<V = unknown> = (value: V) => V | Promise<V>;
type ResponseInterceptor<V = unknown> = (error: V) => V;

const requestPassInterceptor: RequestInterceptor<AxiosResponse> = (response: AxiosResponse) => response;
// const responsePassInterceptor: ResponseInterceptor = (reason: Promise<AxiosError>) => reason;

const interceptor401: ResponseInterceptor<AxiosError> = (reason: AxiosError): AxiosError => {
  const status = reason && reason.response && reason.response.status;

  if (status === 401) {
    if(window.location.pathname !== '/' && !window.location.pathname.includes('/public/')) {
      window.location.href='/';
    }
  }

  throw reason;
};

const interceptorJWT = ((config: AxiosRequestConfig) => {
  if (!config.headers) return config;
  config.headers['Authorization'] = 'Bearer ' + sessionStorage.token;
  return config;
});

const withInterceptors = (props: {
  requestInterceptors?: RequestInterceptor<AxiosRequestConfig>[];
  responseInterceptors?: ResponseInterceptor<AxiosError>[];
  config?: Partial<AxiosRequestConfig>;
}): AxiosInstance => {
  const { requestInterceptors = [], responseInterceptors = [], config = {} } = props;
  const client = axios.create(config);

  requestInterceptors.forEach(interceptor => client.interceptors.request.use(interceptor));
  responseInterceptors.forEach(interceptor => client.interceptors.response.use(requestPassInterceptor, interceptor));

  return client;
};

export const api = withInterceptors({
  config: {
    baseURL: isDevelopment ? 'http://localhost:3003/api' : '/api',
    paramsSerializer: params => qs.stringify(params, { indices: false }),
  },
  responseInterceptors: [interceptor401],
  requestInterceptors: [interceptorJWT]
});