import React, { FC } from 'react';
import { Layout, Menu, MenuProps,  } from 'antd';
import styled from 'styled-components'
import { useNavigate, useLocation } from "react-router-dom";

import { useBooleanFlag } from 'src/hooks';

import { items } from './constants';

const { Sider } = Layout;

const Logo = styled.div`
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
`;

export const MainMenu: FC = () => {
  const { value: collapsed, toggle } = useBooleanFlag(false);
  const navigate = useNavigate();
  const location = useLocation();
  const onSelect: MenuProps['onSelect'] = (info) => {
    navigate(info.key || '/');
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
      <Logo />
      <Menu theme="dark" selectedKeys={[location.pathname]} mode="inline" items={items} onSelect={onSelect}/>
    </Sider>
  );
};