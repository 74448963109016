import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { RouterProvider, Outlet } from "react-router-dom";


import { MainMenu } from 'src/components/menu';
import { Breadcrumbs } from 'src/components/breadcrumb';
import { loadAuthUser, selectAuthUser, selectIsAuthUserLoaded } from './store/auth';


import { ShowIf } from './components/show_if';
import { Loader } from './components/loader';
import { Header } from './components/header';
import { AuthForm } from './components/auth';

import './App.css';


const { Content, Footer } = Layout;

const App = function () {
  const dispatch = useDispatch();
  const isAuthUserLoaded = useSelector(selectIsAuthUserLoaded);
  const authUser = useSelector(selectAuthUser);


  useEffect(() => {
    console.log(window.location.pathname);
    dispatch(loadAuthUser())
  }, []);

  return (
    <>
      <ShowIf value={isAuthUserLoaded} is={true}>
        <Loader size={100} />
      </ShowIf>
      <ShowIf value={isAuthUserLoaded} is={false}>
        <Layout style={{ minHeight: '100vh' }}>
          <MainMenu />
          <Layout className="site-layout">
            <Header />
            <Content style={{ margin: '0 16px' }}>
              <Breadcrumbs />
              <ShowIf value={authUser} is={null}>
                <AuthForm />
              </ShowIf>
              <ShowIf value={Boolean(authUser)} is={true}>
                <Outlet />
              </ShowIf>
            </Content>
            <Footer style={{ textAlign: 'right' }}>Ant Design ©2023 Created by Ant UED</Footer>
          </Layout>
        </Layout>
      </ShowIf>
    </>
  );
};

export default App;