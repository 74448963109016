import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { usePrevious } from 'src/hooks/use_previous';

const Wrapper = styled.div`
  .editor-class {
    min-height: 220px;
    border: 1px solid #F1F1F1;
    padding: 12px;
  }
  .rdw-fontsize-wrapper, .rdw-block-wrapper, .rdw-fontfamily-wrapper, .rdw-remove-wrapper,
  .rdw-colorpicker-wrapper, .rdw-embedded-wrapper, .rdw-image-wrapper, .rdw-text-align-wrapper, .rdw-list-wrapper, .hidden {
    display: none;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
`;

type Props = {
  value?: string;
  name?: string;
  onChange?: (data: string ) => void;
}

export const Textarea: React.FC<Props> = ({ onChange, name, value }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const prevValue = usePrevious(value);
  const shouldUpdateState = !prevValue && Boolean(value?.length);

  useEffect(() => {
    const contentBlock = htmlToDraft(value || '');
    if (contentBlock && shouldUpdateState) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [shouldUpdateState]);

  return (
    <Wrapper>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          inline: {
            monospace: { className: 'hidden' },
            superscript: { className: 'hidden' },
            subscript: { className: 'hidden' },
          }
        }}
        toolbarCustomButtons={[]}
        // hashtag={{
        //   separator: ' ',
        //   trigger: '#',
        // }}
        onChange={(row) => onChange && onChange(draftToHtml(row))}
        onEditorStateChange={setEditorState}
      />
    </Wrapper>
  );
};
