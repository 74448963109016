import { Tags } from "@src/store/tags/model";
import { FormConfig } from "@src/types/common";

export const validateMessages = {
  required: '${label} обязательное поле!',
  types: {
    email: '${label} email задан не верно!',
    number: '${label} не верное заданное число!',
  },
  number: {
    range: '${label} должно быть между ${min} и ${max}',
  },
};

export type FormValues = {
  title: string;
  description: string;
  site: string;
  tags: Tags['id'][];
  image: File;
  place: {
    address: string;
    lat: string | null;
    lng: string | null;
  };
  startTime: Date;
  endTime: Date
}


export const fields: FormConfig<FormValues> = {
  title: {
    label: 'Заголовок',
    name: 'title',
    placeholder: 'Пожалуйста введите заголовок события',
    rules: [{ required: true }]
  },
  tags: {
    label: 'Тэги',
    name: 'tags',
    placeholder: 'Укажите тэги события',
    rules: [{ required: true, type: 'array' }]
  },
  startTime: {
    label: 'Дата начала',
    name: 'startTime',
    placeholder: 'Укажите дату события',
    rules: [{ required: true, type: 'object' as const }]
  },
  endTime: {
    label: 'Дата окончания',
    name: 'endTime',
    placeholder: 'Укажите дату события',
    rules: [{ required: true, type: 'object' as const }]
  },
  place: {
    label: 'Адрес',
    name: 'place',
    placeholder: 'Укажите адрес события',
    rules: [{ required: true, type: 'object' }]
  },
  description: {
    label: 'Описание события',
    name: 'description',
    placeholder: 'Опешите ваше событие',
    rules: [{ required: true }]
  },
  site: {
    label: 'Ссылка на сайт',
    name: 'site',
    placeholder: 'Укажите ссылку на сайт, если имеется',
  },
  image: {
    label: 'Картинка',
    name: 'image',
  }
}