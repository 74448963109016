import React from 'react';
import type { ColumnsType } from 'antd/es/table';

import { Message } from 'src/store/message/model';
import { dateTimeFormat } from 'src/utils/date';
import { Badge, Space } from 'antd';

export const columnsConfig: ColumnsType<Message> = [
  {
    title: 'Пользователь',
    dataIndex: 'user',
    key: 'user',
    render: (user: Message['user']) => user && (
      <Space>
        {user.name}
        <span style={{ color: 'rgba(0, 0, 0, 0.45)', textDecoration: user.isActive ? 'none' : 'line-through' }}>{user.telegramId ? ` (${user.telegramId})` : ''}</span>
        {user.subscription ? <Badge status="success" /> : <Badge status="default" /> }
      </Space>
    ),
  },
  {
    title: 'Сообщение',
    dataIndex: 'text',
    key: 'text',
  },
  {
    title: 'Дата',
    dataIndex: 'ctime',
    key: 'ctime',
    render: (data: Message['ctime']) => dateTimeFormat(data),
  },
];
