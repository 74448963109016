import { createBrowserRouter } from "react-router-dom";

import App from './App';
import Public from "./public";
import { TagsPage } from "./private/tags";
import { EventsPage } from "./private/events";
import { UsersPage } from "./private/users";
import { PublicEventPage } from './public/events';
import { MessagesPage } from "./private/messages";

// for franche
import Parse from "./parse";



export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "events/:action?/:id?",
        element: <EventsPage />,
      },
      {
        path: "tags",
        element: <TagsPage />,
      },
      {
        path: "users",
        element: <UsersPage />,
      },
      {
        path: "messages",
        element: <MessagesPage />,
      }
    ],
  },
  {
    path: "/public",
    element: <Public />,
    children: [
      {
        path: "event/:id",
        element: <PublicEventPage />,
      },
    ]
  },
  {
    path: "/parse",
    element: <Parse />,
  }
]);