import React, { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  z-index: 99999;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;

type Props = {
  size?: number;
}

export const Loader: FC<Props> = ({ size }) => (
  <Wrapper>
    <Spin indicator={<LoadingOutlined style={{ fontSize: size || 50 }} spin />} />
  </Wrapper>);
