import { api } from '../../utils/api';

type FetchAuthUserResponse = {
  token: string;
}
export const fetchAuthUser = () => api.get<FetchAuthUserResponse>('auth').then(res => res.data);

type RegistrateUserParams = {
  login: string;
  password: string;
}
export const registrateUser = (data: RegistrateUserParams) => api.post<unknown>('auth/registrate', data).then(res => res.data);

type LoginUserParams = {
  login: string;
  password: string;
}
export const loginUser = (data: LoginUserParams) => api.post<{ token: string }>('auth/login', data).then(res => res.data);