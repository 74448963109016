import React from 'react';
import { Button, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom';

import { useEvent } from 'src/hooks';
import { useGetFutureEventsQuery } from 'src/utils/api_rtk';

import { UpsertEventPanel } from './components/upsert_panel';
import { AllEventsTable } from './components/all_events';
import { FutureEventsTable } from './components/future_events';
import { Action } from 'src/types/common';



const WrapButton = styled.div`
  text-align: right;
  margin-bottom: 16px;
`;

export const EventsPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id : string, action: Action }>();
  const { refetch } = useGetFutureEventsQuery();

  const onCloseCreatePanel = useEvent(() => {
    navigate(-1);
    refetch();
  })

  return (
    <>
      <WrapButton>
        <Button type="primary" onClick={() => navigate(Action.new)} icon={<PlusOutlined />}>Создать событие</Button>
      </WrapButton>
      
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Предстоящие',
            key: '1',
            children: <FutureEventsTable />,
          },
          {
            label: 'Все',
            key: '2',
            children: <AllEventsTable />,
          },
        ]}
      />
      <UpsertEventPanel open={Boolean(params.action)} idEvent={params.id} onClose={onCloseCreatePanel} />
    </>
  );
};
