import React, { useState } from 'react';
import { Table } from 'antd';

import { Loader } from 'src/components/loader';
import { useGetAllEventsQuery } from 'src/utils/api_rtk';
import { useBooleanFlag, useTableWithActions } from 'src/hooks';
import { Events } from 'src/store/events/model';
import { columns as columnsConfig } from '../../config';
import { ViewEventPanel } from '../view_panel';


export const AllEventsTable: React.FC = () => {
  const [page, setPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState<Events>();
  const { value: viewPanelOpened, toggle: toggleViewPanel } = useBooleanFlag(false);
  const { data, error, isLoading } = useGetAllEventsQuery({ page });
  const { items = [], meta } = data || {};
  const paginationParams = meta ? {
    total: meta.totalItems,
    pageSize: meta.itemsPerPage,
    current: meta.currentPage,
  } : {};

  const actions =  {
    view: (data: Events) => {
      setSelectedEvent(data);
      toggleViewPanel()
    },
    clipboardId: (data: Events) => navigator.clipboard.writeText(data.id),
  }

  const columns = useTableWithActions<Events>({ columns: columnsConfig, actions });

  const onCloseViewPanelHandler = () => {
    toggleViewPanel();
    setSelectedEvent(undefined);
  }

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={items}
        pagination={{ ...paginationParams, onChange: setPage }}
        onRow={(record) => {
          return {
            onClick: (e) => e.target instanceof HTMLElement && e.target?.nodeName === 'TD' && actions.view(record),
          };
        }}
      />
      <ViewEventPanel event={selectedEvent} open={viewPanelOpened} onClose={onCloseViewPanelHandler} />
      {isLoading && <Loader /> }
    </>
  );
};
