import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import { ReturnPromiseType } from '../slicers';

import * as api from './api';
import { loadAuthUser, loadAuthUserFailure, loadAuthUserSuccess } from './slice';


function* loadAuthUserSaga(action: ReturnType<typeof loadAuthUser>) {
  try {
    const response: ReturnPromiseType<typeof api.fetchAuthUser> = yield call(api.fetchAuthUser);
    yield put(loadAuthUserSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(loadAuthUserFailure('Ошибка'));
  }
}

export function* authUserSaga(): Generator<StrictEffect, void> {
  yield takeLatest(loadAuthUser.type, loadAuthUserSaga);
}

