import { Rule } from "antd/es/form";

export type UnknownObject = Record<string, any>;

export type FormConfig<T extends UnknownObject> = Record<keyof T, {
  name: keyof T,
  label?: string;
  placeholder?: string;
  rules?: Rule[];
}>

export type Pagination<T extends UnknownObject> = {
  items: T[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  }
}


export enum Action  {
  new = 'new',
  edit = 'edit',
  view = 'view',
}