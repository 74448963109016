import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Input, Modal, Space } from 'antd';
import styled from 'styled-components'

import { useBooleanFlag } from 'src/hooks';
import { Loader } from 'src/components/loader';
import { createTag } from 'src/store/tags/api';


const Row = styled(Space)`
  width: 100%;
  margin-bottom: 16px;
`;

type Props = {
  open: boolean;
  onClose: () => void;
}

export const ModalCreateTag: FC<Props> = ({ open, onClose }) =>  {
  const { value: loading, setTrue: startLoading, setFalse: stopLoading } = useBooleanFlag(false);
  const [name, setName] = useState('');

  const handleOk = () => {
    startLoading();
    createTag({ name }).finally(() => {
      stopLoading();
      onClose();
    })
  };

  const handleCancel = () => {
    onClose();
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }

  return (
      <Modal
        title="Создание тэга"
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <Row direction="vertical">
          <div>Наименование тэга:</div>
          <Input value={name} onChange={handleChangeName}/>
        </Row>
        { loading && <Loader /> }
      </Modal>
  );
};
