import React from 'react';
import { Button, Table } from 'antd';
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons';

import { useBooleanFlag, useTableWithActions } from 'src/hooks';
import { Loader } from 'src/components/loader';
import { useGetTagsQuery } from 'src/utils/api_rtk';
import { Tags } from 'src/store/tags/model';
import { columns as columnsConfig } from './config';
import { ModalCreateTag } from './components/modal_create_tag';


const WrapButton = styled.div`
  text-align: right;
  margin-bottom: 16px;
`;



export const TagsPage: React.FC = () => {
  const { data, error, isLoading, refetch } = useGetTagsQuery();
  // const [selectedRowData, setSelectedRowData] = useState<Tags>();
  const { value: isUpsertTagModalOpen, setTrue: openUpsertTagModal, setFalse: closeUpsertTagModal } = useBooleanFlag(false);

  const actions =  {
    clipboardId: (data: Tags) => navigator.clipboard.writeText(data.id),
  }

  const columns = useTableWithActions<Tags>({ columns: columnsConfig, actions });

  const onCloseAddTagHandler = () => {
    closeUpsertTagModal();
    refetch();
  }

  return (
    <>
      {error}
      {isLoading && <Loader /> }
      <WrapButton>
        <Button onClick = {openUpsertTagModal} type="primary" icon={<PlusOutlined />}>Создать тэг</Button>
      </WrapButton>
      <Table rowKey="id" columns={columns} dataSource={data} />
      <ModalCreateTag open={isUpsertTagModalOpen} onClose={onCloseAddTagHandler} />
    </>
  );
};
