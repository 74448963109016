import React, { FC, useEffect } from 'react';
import { Button, Drawer, Form, Space, message } from 'antd';
import dayjs from 'dayjs';
// import { useSearchParams } from 'react-router-dom';

import { upsertEvent, transformToFormData, getEvent, previewEvent } from 'src/store/events/api';
import { Loader } from 'src/components/loader';
import { useBooleanFlag } from 'src/hooks';

import { CreateEventForm, FormValues } from './form';
import { ShowIf } from 'src/components/show_if';


type Props = {
  idEvent?: string;
  open: boolean;
  onClose: () => void;
}

export const UpsertEventPanel: FC<Props> = ({ idEvent, open, onClose }) => {
  // const [searchParams] = useSearchParams();
  const [form] = Form.useForm<FormValues>();
  const [messageApi, contextHolder] = message.useMessage();
  const { value: loading, setTrue: startLoading, setFalse: stopLoading } = useBooleanFlag(false);

  const onFinish = async (values: FormValues) => {
    const { image, ...rest } = values;
    const formData = transformToFormData(image, rest);

    try {
      startLoading()
      const preview = await previewEvent({
        title: rest.title,
        description: rest.description,
      });
      if (preview?.text.length >= 1024) {
        form.setFields([
          {
            name: 'description',
            errors: [`Описание событие привысило длину на ${preview?.text.length - 1024} символов`],
          },
        ]);
        throw new Error();
      }
      await upsertEvent(formData);
      stopLoading();
      onClose();
    } catch (e) {
      console.log(e);
      stopLoading();
      messageApi.open({
        type: 'error',
        content: `Ошибка сохранения`,
      });
    }
  }


  useEffect(() => {
    if(!idEvent) {
      form.resetFields();
      return;
    }
    startLoading();
    getEvent(idEvent)
      .then(event => {
        form.setFieldsValue({
          id: event.id,
          title: event.title,
          description: event.description,
          startTime: dayjs(event.startTime),
          endTime: dayjs(event.endTime),
          tags: event.tags.map(t => t.id),
          place: {
            address: event.address,
            lat: event.lat,
            lng: event.lng,
          }
        });
      })
      .finally(stopLoading)
  }, [idEvent]);



  return (
    <Drawer
      title="Создание нового события"
      width={720}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      closable={false}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={form.submit} type="primary" loading={loading}>
            Submit
          </Button>
        </Space>
      }
      >
        {contextHolder}
        <ShowIf value={loading} is={true} >
          <Loader size={92}/>
        </ShowIf>
        <CreateEventForm
          form={form}
          onFinish={onFinish}
        />
      </Drawer>
  )
}
