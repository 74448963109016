import React, { useState } from 'react';
import { Table } from 'antd';

import { Loader } from 'src/components/loader';
import { useGetUsersQuery } from 'src/utils/api_rtk';
import { User } from 'src/store/user/model';
import { useTableWithActions } from 'src/hooks/use_table_with_actions';
import { useBooleanFlag } from 'src/hooks';

import { columnsConfig } from './config';
import { ModalAddTag } from './components/modal_add_tag';



export const UsersPage: React.FC = () => {
  const { data, error, isLoading, refetch } = useGetUsersQuery();
  const [selectedRowData, setSelectedRowData] = useState<User>();
  const { value: isAddTagModalOpen, setTrue: openAddTagModal, setFalse: closeAddTagModal } = useBooleanFlag(false);

  const actions =  {
    editTags: (data: User) => { setSelectedRowData(data); openAddTagModal() },
    clipboardId: (data: User) => navigator.clipboard.writeText(data.id),
  }

  const columns = useTableWithActions<User>({ columns: columnsConfig, actions });

  const onCloseAddTagHandler = () => {
    closeAddTagModal();
    refetch();
  }

  return (
    <>
      {error}
      {isLoading && <Loader /> }
      <Table rowKey="id" columns={columns} dataSource={data} />
      <ModalAddTag open={isAddTagModalOpen} onClose={onCloseAddTagHandler} user={selectedRowData} />
    </>
  );
};
