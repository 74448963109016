import { StateFromReducersMapObject } from '@reduxjs/toolkit';

import { authUserReducer } from './auth/slice';
import { rtkApi } from '../utils/api_rtk';

export const reducer = {
  authUser: authUserReducer,
  [rtkApi.reducerPath]: rtkApi.reducer,
};

export type AppState = StateFromReducersMapObject<typeof reducer>;