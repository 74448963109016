import React, { useCallback, useMemo } from 'react';
import { Button, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { UnknownObject } from 'src/types/common';



type Actions = 'delete' | 'edit' | 'create' | 'addTag' | 'block' | 'editTags' | 'clipboardId' | 'view';

type UseTableWithActions<T extends UnknownObject> = {
  columns: ColumnsType<T>;
  actions: Partial<Record<Actions, (data: T) => void>> | Partial<Record<Actions, { handler: (data: T) => void, label: (data: T) => string }>>;
}

const actionsName: Record<Actions, string> = {
  delete: 'Удалить',
  edit: 'Редактировать',
  create: 'Создать',
  addTag: 'Добавить тэг',
  editTags: 'Редактировать тэги',
  block: 'Блокировать',
  clipboardId: 'Копировать UID',
  view: 'Просмотр',
}

const emptyLabel =  () => 'Имя действия не задано';

export const useTableWithActions = <T extends UnknownObject>({ columns, actions }: UseTableWithActions<T>) => {
 
  const onClickHandler: (data: T) => MenuProps['onClick'] = useCallback((data: T) => ({ key }) => {
    const action = actions[key as Actions];
    if (!action) return;
    typeof action === 'function' ? action(data) : action.handler(data);
  }, [actions]);

  const [actionItems, firstAction] = useMemo(() => {
    const items = Object.entries(actions).map(([key, action]) => ({
      key,
      label: (typeof action === 'function' ? () => actionsName[key as Actions] : action.label) || emptyLabel,
      onSelect: typeof action === 'function' ? action : action.handler,
    }))
    return [items.slice(1), items[0]]
}, [actions]);

  const actionsCell = {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'right' as const,
    render: (_: unknown, data: T) => firstAction && (
      <Space size="middle">
        {actionItems.length ? (
          <Dropdown.Button
            loading={false}
            menu={{ items: actionItems.map(item => ({ ...item, label: item.label(data)})), onClick: onClickHandler(data) }}
            onClick={() => firstAction.onSelect(data)}
          >
            {firstAction.label(data)}
          </Dropdown.Button>
        ) : (
          <Button onClick={() => firstAction.onSelect(data)}>{firstAction.label(data)}</Button>
        )}
      </Space>
    ),
  };
  
  return [...columns, actionsCell];
}