import React from 'react';
import { Table } from 'antd';

import { Loader } from 'src/components/loader';
import { useGetUserMessagesQuery } from 'src/utils/api_rtk';
import { Message } from 'src/store/message/model';
import { useTableWithActions } from 'src/hooks/use_table_with_actions';

import { columnsConfig } from './config';


export const MessagesPage: React.FC = () => {
  const { data, isLoading } = useGetUserMessagesQuery();

  const actions =  {
    clipboardId: (data: Message) => navigator.clipboard.writeText(data.id),
  }

  const columns = useTableWithActions<Message>({ columns: columnsConfig, actions });

  return (
    <>
      {isLoading && <Loader /> }
      <Table rowKey="id" columns={columns} dataSource={data} />
    </>
  );
};
