import dayjs from "dayjs";

export const dateFormat = (date: string) => dayjs(date).format('DD.MM.YYYY');

export const dateTimeFormat = (date: string) => dayjs(date).format('DD.MM.YYYY HH:mm');

export const dateTimeRangeFormat = (dateFrom?: string, dateTo?: string) => {
  if(!dateFrom || !dateTo) return '';

  if (dayjs(dateFrom).diff(dateTo, 'day') >=1) {
    return `${dayjs(dateFrom).format('DD.MM.YYYY HH:mm')} - ${dayjs(dateTo).format('DD.MM.YYYY HH:mm')}`;
  }
  return `${dayjs(dateFrom).format('DD.MM.YYYY')} c ${dayjs(dateFrom).format('HH:mm')} до ${dayjs(dateTo).format('HH:mm')}`
}