import React, { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined } from '@ant-design/icons';
import { Modal, Space, Upload } from 'antd';

import { getBase64 } from 'src/utils/files';
import { useBooleanFlag } from 'src/hooks';

type Props = {
  name?: string;
  onChange?: (data: Blob | null | undefined ) => void;
  fileList?: Blob | string;
  id?: string;
}

export const UploadImage: React.FC<Props> = ({ onChange, id, fileList }) => {
  const [previewImage, setPreviewImage] = useState('');
  const { value: previewOpen, toggle: togglePreviewOpen } = useBooleanFlag(false);

  const handlerRemoveImage = () => {
    onChange && onChange(undefined);
    setPreviewImage('');
  }

  const handleBeforeUpload = async (file: Blob) => {
    const preview = await getBase64(file);
    onChange && onChange(file);
    setPreviewImage(preview);
    return false;
  }

  useEffect(() => {
    if (fileList instanceof File) {
      return;
    }
    if (typeof fileList === 'string' && fileList) {
      fetch(fileList)
        .then(res => res.blob())
        .then((blob) => handleBeforeUpload(blob))
        .catch(() => onChange && onChange(null));
    }
  }, [fileList]);

  return (
    <>
      <ImgCrop rotationSlider aspectSlider modalTitle='Отредактируйте изображение' >
        <Upload
          maxCount={1}
          accept="image/*"
          fileList={previewImage ? [{ url: previewImage, uid: id || '_', name: ''}] : []}
          listType="picture-card"
          beforeUpload={handleBeforeUpload}
          onRemove={handlerRemoveImage}
          onPreview={togglePreviewOpen}
        >
          <Space size={4}>
            <UploadOutlined /> Загрузить
          </Space>
        </Upload>
      </ImgCrop>
      <Modal open={previewOpen} footer={null} onCancel={togglePreviewOpen}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
