import { api } from '../../utils/api';
import { UpsertToken } from './model';


type EditUserTagsParams =  {
  newIds: string[];
  deleted: string[];
}
export const editUserTagsWith = (id: string, data: EditUserTagsParams) => api.put<unknown>(`users/tags/with/${id}`, data).then(res => res.data);

export const editUserTagsWithOut = (id: string, data: EditUserTagsParams) => api.put<unknown>(`users/tags/without/${id}`, data).then(res => res.data);

export const getUpsertToken = (id: string) => api.get<UpsertToken>(`users/token/${id}`).then(res => res.data);