import React from 'react';

import type { ColumnsType } from 'antd/es/table';
import { Tag } from 'antd';

import type { Tags } from 'src/store/tags/model';


export const columns: ColumnsType<Tags> = [
  {
    title: 'Названия',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <Tag color="magenta">{text}</Tag>,
  },
  {
    title: 'Группа',
    dataIndex: 'age',
    key: 'age',
  },
];