import React, { useState } from 'react';
import { Alert, Button, message, Col, Form, Input, Row, Table } from 'antd';

import { FormConfig, UnknownObject } from './types/common';
import styled from 'styled-components';
import { api } from './utils/api';
import { Loader } from './components/loader';

type FormValues = {
  from: number,
  to: number,
}

const validateMessages = {
  required: '${label} обязательное поле!',
  types: {
    email: '${label} email задан не верно!',
    number: '${label} не верное заданное число!',
  },
  number: {
    range: '${label} должно быть между ${min} и ${max}',
  },
};

const fields: FormConfig<FormValues> = {
  from: {
    label: 'Начальный номер',
    name: 'from',
    placeholder: 'Пожалуйста введите начальный номер',
    rules: [{ required: true }]
  },
  to: {
    label: 'Конечный номер',
    name: 'to',
    placeholder: 'Пожалуйста введите конечнй номер',
    rules: [{ required: true }]
  },
 }

const Wrap = styled.div`
  padding: 16px;
  .rdw-emoji-modal {
    left: -225px !important;
  }

`;

const LoaderWrap = styled.div`
  position: absolute;
  top:0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255,255,255,0.4);
`;

type Data = {
  url: string;
  title: string;
} 

const columns = [
  {
    title: 'Адрес',
    dataIndex: 'url',
    key: 'url',
    render: (text: string) => <a href={text} target="_blank">{text}</a>,
  },
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
];

const Parse = function () {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<FormValues>();
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);

  const onFinish = (values: FormValues) => {
    console.log(values);
    if(!Number(values.from) || !Number(values.to) || Number(values.from) - Number(values.to) > 1000) {
      messageApi.open({
        type: 'error',
        content: 'Не верный диапазон. Диапазон должен быть менее 1000',
      });
      return;
    }

    setLoading(true);
    api.post<Data[]>('parse', values)
      .then(res => setData(res.data))
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'Ошибка получения данных, попробуйте уменьшить диапазон',
        });
      })
      .finally(() => {
        setLoading(false)
      })
    // $.post('./parse', { from , to }).then(res => {

  }

  return (
    <Wrap>
      {contextHolder}
      <Form form={form} layout="vertical" validateMessages={validateMessages} onFinish={onFinish} scrollToFirstError >
        <Row gutter={16} style={{ marginBottom: '28px'}}>
          <Col span={8}>
            <Alert message="Диапазон больше чем 100, может потребовать очень долгое время обработки" type="warning" />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              name={fields.from.name}
              label={fields.from.label}
              rules={fields.from.rules}
            >
              <Input placeholder={fields.from.placeholder} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={fields.to.name}
              label={fields.to.label}
              rules={fields.to.rules}
            >
              <Input placeholder={fields.to.placeholder} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button onClick={form.submit} type="primary" style={{ marginTop: '28px'}}>
              Поиск
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? <LoaderWrap><Loader size={72} /></LoaderWrap> : <Table dataSource={data} columns={columns} pagination={{ pageSize: 5000 }} />}
    </Wrap>
  );
};

export default Parse;