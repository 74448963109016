import React, { FC } from 'react';
import { theme, Layout  } from 'antd';
import styled from 'styled-components'

const { Header: HeaderUI } = Layout;

const StyledHeader = styled(HeaderUI)<{ $background: string }>`
  background: ${props => props.$background} !important;
`;

export const Header: FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  return <StyledHeader $background = {colorBgContainer } />
}