import React, { FC, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import ReactMarkdown  from 'react-markdown';

import { Events } from 'src/store/events/model';
import { Loader } from 'src/components/loader';
import { ROOT_URL } from 'src/utils/api_rtk';
import { previewEvent } from 'src/store/events/api';
import { useBooleanFlag } from 'src/hooks';
import { ShowIf } from 'src/components/show_if';


const Message = styled.div`
  width: 420px;
  background: #e0eeff;
  border-radius: 7px;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 12px;
  padding-top: 0px;
`;

const Img = styled.img`
  max-width: 100%;
`

type Props = {
  event?: Events;
  open: boolean;
  onClose: () => void;
}

export const ViewEventPanel: FC<Props> = ({ event, open, onClose }) => {
  const [text, setText] = useState<string>('');
  const { value: loading, setTrue: startLoading, setFalse: stopLoading } = useBooleanFlag(false);

  useEffect(() => {
    if(!event) return;
    startLoading();
    previewEvent(event)
      .then((data) => {
        setText(data.text);
      })
      .finally(stopLoading);
  }, [event]);

  return (
  <Drawer
    title={`Просмотр события`}
    width={520}
    onClose={onClose}
    open={open}
    bodyStyle={{ paddingBottom: 80 }}
    closable={false}
    >
      <ShowIf value={Boolean(event)} is={true}>
        <Message>
          {event?.image && <Img src={`${ROOT_URL}/files/${event.image}`}></Img>}
          <Content>
            <ReactMarkdown children={text} />
          </Content>
        </Message>
      </ShowIf>
      {loading && <Loader />}
    </Drawer>
)}
