import { isNull } from 'lodash';
import { createSelector } from 'reselect';

import { AppState } from '../root_reducer';


export const selectDomain = (state: AppState) => state.authUser;

export const selectIsAuthUserLoaded = createSelector(selectDomain, branch => branch.isLoading || (!branch.error && isNull(branch.item)));

export const selectAuthUser = createSelector(selectDomain, branch => branch.item);