import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom';

import { items as menuItems } from '../menu/constants';



const Wrapper = styled.div`
  margin: 16px 0;
`;

export const Breadcrumbs: FC = () => {
  const location = useLocation();

  const links = location.pathname.split('/')
    .filter(Boolean)
    .reduce((acc, path) => {
      const item = menuItems.find(item => item?.key === `/${path}`);
      if (item) acc.push({ title: <Link to={item.key}>{item.label}</Link>, key: item.key });
      return acc
    }, [{ title: <Link to={'/'}>Главная</Link>, key: '/' }]);

  return (
    <Wrapper>
      <Breadcrumb items={links} />
    </Wrapper>
  );
};