import { isArray } from 'lodash';
import { UnknownObject } from 'src/types/common';
import { api } from '../../utils/api';
import { Events } from './model';


export const transformToFormData = (image: File, obj: Record<string, string | UnknownObject | undefined>) => {
  const formData = new FormData();

  image && formData.append('image', image);
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && "format" in value && typeof value.format === 'function' ) {
      formData.append(key, value.format());
    } else if (isArray(value)) {
      value.forEach((item) => formData.append(`${key}[]`, item))
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([subKey, value]) => {
        value && formData.append(`${subKey}`, value);
      });
    } else {
      value && formData.append(key, value);
    }
  });
  return formData;
}

export const upsertEvent = (data: FormData, token?: string) => api.post<Events>(token ? `events?token=${token}` : 'events', data).then(res => res.data);

export const getEvent = (id: string) => api.get<Events>(`events/${id}`).then(res => res.data);

export const publicEvent = (id: string, value: boolean) => api.put<Events>(`events/public/${id}`, { public: value }).then(res => res.data);

export const previewEvent = (event: Partial<Events>) => api.post<{ text: string }>(`events/preview`, event).then(res => res.data);

