import React, { useState } from 'react';
import { Table, message } from 'antd';

import { Loader } from 'src/components/loader';
import { useGetFutureEventsQuery } from 'src/utils/api_rtk';
import { useBooleanFlag, useTableWithActions } from 'src/hooks';
import { Events } from 'src/store/events/model';
import { publicEvent } from 'src/store/events/api';
import { columns as columnsConfig } from '../../config';
import { ViewEventPanel } from '../view_panel';
import { useNavigate } from 'react-router-dom';
import { Action } from 'src/types/common';



export const FutureEventsTable: React.FC = () => {
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState<Events>();
  const [messageApi, contextHolder] = message.useMessage();
  const { value: viewPanelOpened, toggle: toggleViewPanel } = useBooleanFlag(false);
  const { data: items = [], error, isLoading, refetch } = useGetFutureEventsQuery();

  const actions =  {
    view: (data: Events) => {
      setSelectedEvent(data);
      toggleViewPanel()
    },
    edit: (data: Events) => {
      if (data.public) {
        messageApi.open({
          content: 'Опубликованное событие нельзя редактировать',
        });
      } else {
        navigate(`${Action.edit}/${data.id}`);
      }
    },
    clipboardId: (data: Events) => navigator.clipboard.writeText(data.id),
    setPublic: {
      handler: (data: Events) => {
        publicEvent(data.id, !data.public)
          .then(() => refetch())
          .catch(() => {
            messageApi.open({
              type: 'error',
              content: 'Ошибка сохранения данных',
            });
          })
      },
      label: (data: Events) => data.public ? 'Снять с публикации' : 'Опубликовать',
    }
  }

  const columns = useTableWithActions<Events>({ columns: columnsConfig, actions });

  const onCloseViewPanelHandler = () => {
    toggleViewPanel();
    setSelectedEvent(undefined);
  }

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={items}
        onRow={(record) => {
          return {
            onClick: (e) => e.target instanceof HTMLElement && e.target?.nodeName === 'TD' && actions.view(record),
          };
        }}
      />
      <ViewEventPanel event={selectedEvent} open={viewPanelOpened} onClose={onCloseViewPanelHandler} />
      {isLoading && <Loader /> }
      {contextHolder}
    </>
  );
};
