
import { createEntitySlice, ReturnPromiseType } from '../slicers';
import { fetchAuthUser } from './api';


const authUserSlice = createEntitySlice<ReturnPromiseType<typeof fetchAuthUser>>({
  name: 'authUser',
  prepareRequest: () => ({ payload: { } }),
});

export const {
  request: loadAuthUser,
  success: loadAuthUserSuccess,
  failure: loadAuthUserFailure,
  reset: resetAuthUserState,
} = authUserSlice.actions;

export const authUserReducer = authUserSlice.reducer;