import { configureStore as createStore, getDefaultMiddleware, Middleware, Store } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { setupListeners } from '@reduxjs/toolkit/query'

import { isDevelopment } from '../utils/is_development';
import { rtkApi } from '../utils/api_rtk';
import { reducer } from './root_reducer';
import { mainSaga } from './root_saga';

const configureStore = (): Store => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware: Middleware[] = [...getDefaultMiddleware(), sagaMiddleware, rtkApi.middleware];

  const store = createStore({
    reducer,
    middleware,
    devTools: isDevelopment,
  });

  sagaMiddleware.run(mainSaga);

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  setupListeners(store.dispatch)

  return store;
};

export { configureStore };
