import { isFunction } from 'lodash';
import { useRef, useMemo } from 'react';

export type Callback = (...args: any[]) => unknown;

export const useEvent = <T extends Callback = Callback>(callback: T): T => {
  const copy = useRef<T>();
  copy.current = callback;
  return useMemo<T>(() => ((...args) => isFunction(copy.current) && copy.current(...args)) as T, []);
};
