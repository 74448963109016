import React, { FC, useEffect, useState } from 'react';
import { Modal, Select, Space } from 'antd';
import styled from 'styled-components'

import { useBooleanFlag } from 'src/hooks';
import { User } from 'src/store/user/model';
import { useGetTagsQuery } from 'src/utils/api_rtk';
import { Loader } from 'src/components/loader';
import { editUserTagsWith, editUserTagsWithOut } from 'src/store/user/api';

const Row = styled(Space)`
  width: 100%;
  margin-bottom: 16px;
`;

type Props = {
  open: boolean;
  user?: User;
  onClose: () => void;
}

export const ModalAddTag: FC<Props> = ({ open, onClose, user }) =>  {
  const { value: loading, setTrue: startLoading, setFalse: stopLoading } = useBooleanFlag(false);
  const [withTags, setWithTags] = useState<string[]>([]);
  const [withOutTags, setWithOutTags] = useState<string[]>([]);
  const { data, isLoading } = useGetTagsQuery();

  const handleOk = () => {
    const initialWithTagsIds = (user?.withTags || []).map(tag => tag.id);
    const initialWithOutTagsIds = (user?.withOutTags || []).map(tag => tag.id);
    startLoading()

    user && editUserTagsWith(user.id, { newIds: withTags, deleted: initialWithTagsIds })
      .then(() => editUserTagsWithOut(user.id, { newIds: withOutTags, deleted: initialWithOutTagsIds }))
      .finally(() => {
        stopLoading();
        onClose();
      });
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    setWithTags((user?.withTags || []).map(tag => tag.id));
    setWithOutTags((user?.withOutTags || []).map(tag => tag.id));
  }, [user]);

  return (
      <Modal
        title="Редактирование #тэгов пользователя"
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <Row direction="vertical">
          <div>Получать уведомления по:</div>
          <Select mode="multiple" placeholder={'Выберите тэги'} style={{ width: '100%' }} value={withTags} onChange={setWithTags}>
            {data && data.map(tag => <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>)}
          </Select>
        </Row>
        <Row direction="vertical">
          <div>Не получать уведомления по:</div>
          <Select mode="multiple" placeholder={'Выберите тэги'} style={{ width: '100%' }} value={withOutTags} onChange={setWithOutTags}>
            {data && data.map(tag => <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>)}
          </Select>
        </Row>
        { isLoading && <Loader /> }
      </Modal>
  );
};
