import React from 'react';
import { Outlet } from "react-router-dom";

const Public = function () {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Public;