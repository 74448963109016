import { createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers, Slice } from '@reduxjs/toolkit';

import { CaseReducers, ActionReducerMapBuilder } from '@reduxjs/toolkit';


type FunctionAnyArgsVoid = (...args: unknown[]) => void;

type RequestMeta = {
  onSuccess?: FunctionAnyArgsVoid;
  onFailure?: FunctionAnyArgsVoid;
};

type DefaultPrepareFn = (...args: any[]) => { payload: unknown; meta?: RequestMeta };

// https://github.com/reduxjs/redux-toolkit/blob/f7c5e5a51c19f47b728ecaa296054cf12e7ebbbc/src/tsHelpers.ts#L93-L100
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NoInfer<T> = [T][T extends any ? 0 : never];

// https://github.com/reduxjs/redux-toolkit/blob/f7c5e5a51c19f47b728ecaa296054cf12e7ebbbc/src/createSlice.ts#L131-L133
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtraReducers<State = any> = CaseReducers<NoInfer<State>, any> | ((builder: ActionReducerMapBuilder<NoInfer<State>>) => void);

export type ReturnPromiseType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

export type SliceReducers<T> = SliceCaseReducers<StoreEntityBranch<T>>;

export type StoreListBranch<T> = {
  items: T[];
  isLoading: boolean;
  error: string | null;
};

export type StoreEntityBranch<T> = {
  item: T | null;
  isLoading: boolean;
  error: string | null;
};


export const INITIAL_ENTITY_BRANCH_STATE = {
  isLoading: false,
  item: null,
  error: null,
};

export const INITIAL_LIST_BRANCH_STATE = {
  isLoading: false,
  items: [],
  error: null,
  initial: true,
};

export const createEntitySlice = <T, Reducers extends SliceReducers<T> = SliceReducers<T>, PrepareFn extends DefaultPrepareFn = DefaultPrepareFn>({
  name = '',
  initialState = INITIAL_ENTITY_BRANCH_STATE,
  reducers,
  extraReducers,
  prepareRequest,
}: {
  name: string;
  initialState?: StoreEntityBranch<T>;
  reducers?: ValidateSliceCaseReducers<StoreEntityBranch<T>, Reducers>;
  extraReducers?: ExtraReducers<StoreEntityBranch<T>>;
  prepareRequest?: PrepareFn;
}) => {
  const predefinedReducers = {
    request: {
      reducer(state: StoreEntityBranch<T>) {
        state.isLoading = true;
      },
      prepare: prepareRequest as PrepareFn,
    },
    success(state: StoreEntityBranch<T>, action: PayloadAction<T>) {
      state.item = action.payload;
      state.isLoading = false;
    },
    failure(state: StoreEntityBranch<T>, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    reset: () => INITIAL_ENTITY_BRANCH_STATE,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      ...predefinedReducers,
      ...(reducers as ValidateSliceCaseReducers<StoreEntityBranch<T>, Reducers>),
    },
    extraReducers,
  }) as Slice<StoreEntityBranch<T>, Reducers & typeof predefinedReducers, typeof name>;
};

export const createListSlice = <T, Reducers extends SliceCaseReducers<StoreListBranch<T>>, PrepareFn extends DefaultPrepareFn>({
  name = '',
  initialState,
  reducers,
  extraReducers,
  prepareRequest,
}: {
  name: string;
  initialState: StoreListBranch<T>;
  reducers?: ValidateSliceCaseReducers<StoreListBranch<T>, Reducers>;
  extraReducers?: ExtraReducers<StoreListBranch<T>>;
  prepareRequest?: PrepareFn;
}) => {
  const predefinedReducers = {
    request: {
      reducer(state: StoreListBranch<T>) {
        state.isLoading = true;
      },
      prepare: prepareRequest as PrepareFn,
    },
    success(state: StoreListBranch<T>, action: PayloadAction<T[]>) {
      state.items = action.payload;
      state.isLoading = false;
    },
    failure(state: StoreListBranch<T>, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    reset: () => INITIAL_LIST_BRANCH_STATE,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      ...predefinedReducers,
      ...(reducers as ValidateSliceCaseReducers<StoreListBranch<T>, Reducers>),
    },
    extraReducers,
  }) as Slice<StoreListBranch<T>, Reducers & typeof predefinedReducers, typeof name>;
};
