import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import dayjs from 'dayjs';
import { Alert, Button, Col, DatePicker as DatePickerUI, Form, Input, Row, Select, message } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';

import { FormValues, fields, validateMessages } from './config';
import { Textarea } from 'src/components/fields/textarea';
import { UploadImage } from 'src/components/fields/upload';
import { SelectAddress } from 'src/components/fields/address';
import { useGetTagsQuery } from 'src/utils/api_rtk';
import { upsertEvent, getEvent, transformToFormData } from 'src/store/events/api';
import { getUpsertToken } from 'src/store/user/api';
import { useBooleanFlag } from 'src/hooks';
import { ShowIf } from 'src/components/show_if';
import { Events } from 'src/store/events/model';
import { getErrorMessageFromResponse } from 'src/utils/helper';

const { Option } = Select;

const Wrap = styled.div`
  padding: 16px;
  .rdw-emoji-modal {
    left: -225px !important;
  }

`;

const DatePicker = styled(DatePickerUI)`
  width: 100%;
`

export const PublicEventPage: React.FC = () => {
  const params = useParams<{ id : string }>();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm<FormValues>();
  const {value: isTokenNotValid, setTrue: setTokenNotValid } = useBooleanFlag(false);
  const {value: loading, setTrue: startLoading, setFalse: stopLoading } = useBooleanFlag(true);
  const [event, setEvent] = useState<Events>();
  const [messageApi, contextHolder] = message.useMessage();
  
  const { data = [] } = useGetTagsQuery();
  const isEditMode = Boolean(event?.id);

  const onFinish = (values: FormValues) => {
    const { image, ...rest } = values;
    const formData = transformToFormData(image, { ...rest, id: event?.id });
    upsertEvent(formData, params.id)
      .then(event => {
        form.resetFields()
        event && setEvent(event);
        messageApi.open({
          type: 'success',
          content: isEditMode ? 'Событие обновлено' : 'Событие создано, в скором времени событие опубликуется',
        });
      })
      .catch((err) => {
        console.warn(err);
        messageApi.open({
          type: 'error',
          content: getErrorMessageFromResponse(err),
        });
      }).finally(form.resetFields);

  }

  useEffect(() => {
    startLoading();
    params.id && getUpsertToken(params.id)
      .then(token => {
        if (!token) {
          setTokenNotValid();
          return;
        }
        if (dayjs(token.expires).toISOString() < dayjs().toISOString()) {
          setTokenNotValid();
          return;
        }
        if (token.eventId) {
          return getEvent(token.eventId);
        }
      })
      .then(event => {
        event && setEvent(event);
      })
      .catch(console.log)
      .finally(stopLoading)
  }, [params]);

  return (
    <Wrap>
      {contextHolder}
      <ShowIf value={loading} is={false} >
        <ShowIf value={isTokenNotValid} is={true}>
          <Alert
            message="Токет не дейсвителен"
            description="Если вы создаете объявление через телеграмм бот, то вам необходимо сгенерировать новую ссылку для создания\редактирования события"
            type="error"
          />
        </ShowIf>
        <ShowIf value={isTokenNotValid} is={false} >
          <Form
            form={form}
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={event ? {
              title: event.title,
              description: event.description,
              startTime: dayjs(event.startTime),
              endTime: dayjs(event.endTime),
              tags: event.tags.map(t => t.id),
              place: {
                address: event.address,
                lat: event.lat,
                lng: event.lng,
              },
              image: event.image,
            } : {}}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name={fields.title.name}
                  label={fields.title.label}
                  rules={fields.title.rules}
                >
                  <Input placeholder={fields.title.placeholder} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={fields.tags.name}
                label={fields.tags.label}
                rules={fields.tags.rules}
              >
                <Select mode="multiple" placeholder={fields.tags.placeholder}>
                  {data.map(tag => <Option key={tag.id} value={tag.id}>{tag.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={fields.place.name}
                label={fields.place.label}
                rules={fields.place.rules}
              >
                <SelectAddress withMap lat={searchParams.get('lat')} lng={searchParams.get('lng')} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={fields.startTime.name}
                label={fields.startTime.label}
                rules={fields.startTime.rules}
              >
                <DatePicker
                  showTime
                  placeholder="Дата и время начала"
                  format="DD.MM.YYYY HH:mm"
                  popupClassName='mobile'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={fields.endTime.name}
                label={fields.endTime.label}
                rules={fields.endTime.rules}
              >
                <DatePicker
                  showTime
                  placeholder="Дата и время окончания"
                  format="DD.MM.YYYY HH:mm"
                  popupClassName='mobile'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={fields.description.name}
                label={fields.description.label}
                rules={fields.description.rules}
              >
                <Textarea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={fields.image.name} label="Постер (картинка не более 4мб)" valuePropName="fileList">
                <UploadImage />
              </Form.Item>
            </Col>
          </Row>
            <Row gutter={16}>
              <Button onClick={form.submit} type="primary">
                {isEditMode ? 'Редактировать' : 'Отправить на модерацию'}
              </Button>
            </Row>
          </Form>
        </ShowIf>
      </ShowIf>
    </Wrap>
  )
}