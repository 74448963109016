const earthRadiusKm = 6371.0;
const M_PI = Math.PI;

const deg2rad = (deg: number) => {
  return (deg * M_PI) / 180;
};
const rad2deg = (rad: number) => {
  return (rad * 180) / M_PI;
};

export const distanceEarth = (
  lat1d: number,
  lon1d: number,
  lat2d: number,
  lon2d: number,
) => {
  const lat1r = deg2rad(lat1d);
  const lon1r = deg2rad(lon1d);
  const lat2r = deg2rad(lat2d);
  const lon2r = deg2rad(lon2d);
  const u = Math.sin((lat2r - lat1r) / 2);
  const v = Math.sin((lon2r - lon1r) / 2);
  return (
    2.0 *
    earthRadiusKm *
    Math.asin(Math.sqrt(u * u + Math.cos(lat1r) * Math.cos(lat2r) * v * v))
  );
};

export const inCircle = (
  lat1d: number,
  lon1d: number,
  lat2d: number,
  lon2d: number,
  R: number = Number.MAX_VALUE,
  strict = false,
) => {
  if (!lat1d || !lon1d || !lat2d || !lon2d) return !strict;

  const distance = distanceEarth(lat1d, lon1d, lat2d, lon2d);
  
  if (distance * 1000 > R) {
    return false;
  }
  return true;
};

export const DEFAULT_LAT = '54.9394';
export const DEFAULT_LNG = '83.1899';

export const CITY = {
  divnogorsk: {
    name: 'Дивногорск',
    latitude: 55.9629,
    longitude: 92.3851,
  },
  colcovo: {
    name: 'Кольцово',
    latitude: 54.9394,
    longitude: 83.1899,
  }
}