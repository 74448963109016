import React from 'react';
import {
  DesktopOutlined,
  SettingOutlined,
  TagsOutlined,
  BookOutlined,
  UserOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import type { MenuProps } from 'antd';

export type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: React.ReactNode,
  key: string,
  icon: React.ReactNode,
  children: MenuItem[] | null = null,
) => {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const items = [
  getItem('Главная', '/', <DesktopOutlined />),
  getItem('События', '/events', <BookOutlined />),
  getItem('Пользователи', '/users', <UserOutlined />),
  getItem('Тэги', '/tags', <TagsOutlined />),
  getItem('Сообщения', '/messages', <MessageOutlined />),
  getItem('Настройки', '9', <SettingOutlined />, []),
];

