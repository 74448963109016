import React from 'react';
import { Badge, Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { QqOutlined, RobotOutlined } from '@ant-design/icons';

import type { User } from 'src/store/user/model';
import { CITY, inCircle } from 'src/utils/gis';

export const columnsConfig: ColumnsType<User> = [
  {
    title: 'Логин/telegramId',
    dataIndex: 'login',
    key: 'login',
    render: (login: User['login'], row: User) => (
      <Space>
        {row.isAdmin && <QqOutlined />}
        {row.isModerator && <RobotOutlined />}
        {login}
        <span style={{ color: 'rgba(0, 0, 0, 0.45)', textDecoration: row.isActive ? 'none' : 'line-through' }}>{row.telegramId ? ` (${row.telegramId})` : ''}</span>
        {row.subscription ? <Badge status="success" /> : <Badge status="default" /> }
      </Space>
    ),
  },
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Дистанция',
    dataIndex: 'distance',
    key: 'distance',
    render: (data: User['distance']) => data ? `${data} м` : '-',
  },
  {
    title: 'Локация',
    dataIndex: 'latitude',
    key: 'distance',
    render: (data: User['latitude'], row) => {
      if (!data) return '-';
      const city = Object.values(CITY).find(item => inCircle(row.latitude, row.longitude, item.latitude, item.longitude, 50000, true));
      return city ? `${city.name} (${row.latitude} - ${row.longitude})` : `(${row.latitude} - ${row.longitude})`;
    },
  },
  {
    title: 'Получать по Тэгам',
    dataIndex: 'withTags',
    key: 'withTags',
    render: (data: User['withTags']) => (data || []).map(tag => (<Tag color="magenta" key={tag.id}>{tag.name}</Tag>)),
  },
  {
    title: 'Исключить Тэги',
    dataIndex: 'withOutTags',
    key: 'withOutTags',
    render: (data: User['withOutTags']) => (data || []).map(tag => (<Tag color="warning" key={tag.id}>{tag.name}</Tag>)),
  },
];