import React from 'react';

import type { ColumnsType } from 'antd/es/table';
import { Tag } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { Events } from 'src/store/events/model';

import type { FormValues } from './components/upsert_panel/form';
import type { FormConfig } from 'src/types/common';
import { dateTimeRangeFormat } from 'src/utils/date';



export const columns: ColumnsType<Events> = [
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Тэги',
    dataIndex: 'tags',
    key: 'tags',
    render: (data: Events['tags']) => (data || []).map(tag => (<Tag color="magenta" key={tag.id}>{tag.name}</Tag>)),
  },
  {
    title: 'Место',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Дата',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (data: Events['startTime'], record) => dateTimeRangeFormat(data, record.endTime),
  },
  {
    title: 'Опубликовано',
    dataIndex: 'public',
    key: 'public',
    align: 'center',
    render: (data: Events['public']) => <CheckCircleTwoTone twoToneColor={data ? "#52c41a" : '#c7c7c7'} />
  },
];



export const validateMessages = {
  required: '${label} обязательное поле!',
  types: {
    email: '${label} email задан не верно!',
    number: '${label} не верное заданное число!',
  },
  number: {
    range: '${label} должно быть между ${min} и ${max}',
  },
};


export const fields: FormConfig<FormValues> = {
  id: {
    label: 'Идентификатор',
    name: 'id',
  },
  title: {
    label: 'Заголовок',
    name: 'title',
    placeholder: 'Пожалуйста введите заголовок события',
    rules: [{ required: true }]
  },
  tags: {
    label: 'Тэги',
    name: 'tags',
    placeholder: 'Укажите тэги события',
    rules: [{ required: true, type: 'array' }]
  },
  startTime: {
    label: 'Дата начала',
    name: 'startTime',
    placeholder: 'Укажите дату события',
    rules: [{ required: true, type: 'object' as const }]
  },
  endTime: {
    label: 'Дата окончания',
    name: 'endTime',
    placeholder: 'Укажите дату события',
    rules: [{ required: true, type: 'object' as const }]
  },
  place: {
    label: 'Адрес',
    name: 'place',
    placeholder: 'Укажите адрес события',
    rules: [{ required: true, type: 'object', validator: async (rule, value: FormValues['place']) => { 
        if (!value?.address) {
          throw new Error('Укажите адрес');
        }
        if (!value?.lat || !value?.lng) {
          throw new Error('Укажите место на карте');
        }
      }
    }],
  },
  description: {
    label: 'Описание события',
    name: 'description',
    placeholder: 'Опешите ваше событие',
    rules: [{ required: true }]
  },
  site: {
    label: 'Ссылка на сайт',
    name: 'site',
    placeholder: 'Укажите ссылку на сайт, если имеется',
  },
  image: {
    label: 'Картинка',
    name: 'image',
  }
}