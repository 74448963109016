
import React, { FC } from 'react';
import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';

import { Tags } from 'src/store/tags/model';
import { useGetTagsQuery } from 'src/utils/api_rtk';

import { UploadImage } from 'src/components/fields/upload';
import { Textarea } from 'src/components/fields/textarea';
import { SelectAddress } from 'src/components/fields//address';
import { fields, validateMessages } from '../../../config';
import { Dayjs } from 'dayjs';

const { Option } = Select;

export type FormValues = {
  id?: string;
  title: string;
  description: string;
  site: string;
  tags: Tags['id'][];
  image: File;
  place: {
    address: string;
    lat: number | string | null;
    lng: number | string | null;
  };
  startTime: Dayjs;
  endTime: Dayjs;
}

type Props = {
  form: FormInstance<FormValues>;
  onFinish: (data: FormValues) => void;
}

export const CreateEventForm: FC<Props> = ({ form, onFinish }) => {
  const { data = [] } = useGetTagsQuery();

  return (
    <Form form={form} layout="vertical" validateMessages={validateMessages} onFinish={onFinish} scrollToFirstError >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={fields.id.name}
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={fields.title.name}
            label={fields.title.label}
            rules={fields.title.rules}
          >
            <Input placeholder={fields.title.placeholder} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={fields.tags.name}
            label={fields.tags.label}
            rules={fields.tags.rules}
          >
            <Select mode="multiple" placeholder={fields.tags.placeholder}>
              {data.map(tag => <Option key={tag.id} value={tag.id}>{tag.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={fields.place.name}
            label={fields.place.label}
            rules={fields.place.rules}
          >
            <SelectAddress withMap lat={"1"} lng={"1"} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name={fields.startTime.name}
            label={fields.startTime.label}
            rules={fields.startTime.rules}
          >
            <DatePicker
              showTime
              placeholder="Дата и время начала"
              format="DD.MM.YYYY HH:mm"
              minuteStep={15}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={fields.endTime.name}
            label={fields.endTime.label}
            rules={fields.endTime.rules}
          >
            <DatePicker
              showTime
              placeholder="Дата и время окончания"
              format="DD.MM.YYYY HH:mm"
              minuteStep={15}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={fields.description.name}
            label={fields.description.label}
            rules={fields.description.rules}
          >
            <Textarea />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name={fields.image.name} label="Постер (картинка не более 4мб)" valuePropName="fileList">
            <UploadImage />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
